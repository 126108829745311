import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import filter from 'lodash/fp/filter';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import CardSchemesContext from '~/shared/providers/CardSchemesContext';
import dataSelector from '~/shared/util/data-selector';
import CardSchemesCollection from '~/shared/components/CardSchemesCollection';

export const SCHEME_MAP = {
  alelo: true,
  amex: true,
  applePay: true,
  bancoEstado: true,
  boleto: true,
  dankort: true,
  diners: true,
  discover: true,
  ec: true,
  elo: true,
  eftpos: true,
  googlePay: true,
  hiper: true,
  hipercard: true,
  lgbt: true,
  maestro: true,
  mastercard: true,
  nfc: true,
  pix: true,
  postFinance: true,
  redCompra: true,
  samsungPay: true,
  sodexo: true,
  ticket: true,
  unionPay: true,
  visa: true,
  visaElectron: true,
  vpay: true,
  vr: true,
  sepa: true,
  fasterPayments: true,
  jcb: true,
};

/**
 * A CardScheme component which displays the supported card types.
 */
function CardSchemes({ className, cardSchemes }) {
  const cardSchemesFromContext = useContext(CardSchemesContext);
  const cardSchemesLocal = cardSchemes || cardSchemesFromContext;

  if (isEmpty(cardSchemesLocal)) {
    return null;
  }

  const iconIds = filter((scheme) => SCHEME_MAP[scheme], cardSchemesLocal);

  return (
    <Grid
      data-selector={dataSelector('section', 'card_schemes')}
      data-elbcontext="component:card_schemes"
    >
      <Row>
        <Col
          span="12"
          data-selector={dataSelector('schemes_list', 'card_schemes')}
        >
          <CardSchemesCollection className={className} iconIds={iconIds} />
        </Col>
      </Row>
    </Grid>
  );
}

CardSchemes.propTypes = {
  className: PropTypes.string,
  cardSchemes: PropTypes.arrayOf(PropTypes.string),
};

/**
 * @component
 */
export default CardSchemes;
