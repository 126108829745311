import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import keys from 'lodash/fp/keys';
import {
  Alelo,
  AmericanExpress,
  ApplePay,
  BancoEstado,
  Boleto,
  Dankort,
  DinersClub,
  Discover,
  Ec,
  Elo,
  FasterPayments,
  GooglePay,
  Hiper,
  Hipercard,
  Maestro,
  Mastercard,
  NfcPayment,
  PixFull,
  PostFinance,
  RedCompra,
  SamsungPay,
  Sepa,
  Sodexo,
  Ticket,
  UnionPay,
  VisaElectron,
  Visa,
  Vpay,
  Vr,
  Jcb,
  Eftpos,
} from '@sumup/icons';

import LGBT from '~/shared/components/icons/LgbtIcon';

export const iconComponents = {
  alelo: Alelo,
  amex: AmericanExpress,
  applePay: ApplePay,
  bancoEstado: BancoEstado,
  boleto: Boleto,
  dankort: Dankort,
  diners: DinersClub,
  discover: Discover,
  ec: Ec,
  elo: Elo,
  eftpos: Eftpos,
  fasterPayments: FasterPayments,
  googlePay: GooglePay,
  hiper: Hiper,
  hipercard: Hipercard,
  lgbt: LGBT,
  maestro: Maestro,
  mastercard: Mastercard,
  nfc: NfcPayment,
  pix: PixFull,
  postFinance: PostFinance,
  redCompra: RedCompra,
  samsungPay: SamsungPay,
  sepa: Sepa,
  sodexo: Sodexo,
  ticket: Ticket,
  unionPay: UnionPay,
  visa: Visa,
  visaElectron: VisaElectron,
  vpay: Vpay,
  vr: Vr,
  jcb: Jcb,
};

const wrapperStyles = ({ theme }) => css`
  height: ${theme.spacings.peta};
  width: auto;
  max-width: ${theme.spacings.zetta};
`;

const IconWrapper = styled('div')(wrapperStyles);

/**
 * A single card scheme icon
 */
const PaymentMethodIcon = ({ iconId = '' }) => {
  const IconSvg = iconComponents[iconId];
  if (!IconSvg) {
    return null;
  }

  const StyledIcon = styled(IconSvg)(css`
    width: auto;
    max-width: 100%;
    height: 100%;
    display: inline-block;
    line-height: 0;
  `);

  return (
    <IconWrapper>
      <StyledIcon size={'24'} />
    </IconWrapper>
  );
};

PaymentMethodIcon.propTypes = {
  /**
   * The id of the card scheme icon.
   */
  iconId: PropTypes.oneOf(keys(iconComponents)),
};

/**
 * @component
 */
export default PaymentMethodIcon;
