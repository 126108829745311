import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import PaymentMethodIcon from './components/PaymentMethodIcon';

import dataSelector from '~/shared/util/data-selector';

const iconStyles = ({ theme }) => css`
  box-sizing: content-box;
  display: inline-block;
  padding: ${theme.spacings.byte};
`;

const listWrapperBaseStyles = () => css`
  display: block;
  line-height: 0;
  text-align: center;
`;

const CardSchemeWrapper = styled('ul')(listWrapperBaseStyles);
const PaymentMethodIconWrap = styled('li')(iconStyles);

/**
 *   Displays a row of available or active card scheme icons
 */
const CardSchemesCollection = ({ iconIds = [] }) => {
  if (isEmpty(iconIds)) {
    return null;
  }

  return (
    <CardSchemeWrapper>
      {iconIds.map((iconId) => (
        <PaymentMethodIconWrap
          key={iconId}
          data-selector={dataSelector('schemes_list_item', 'card_schemes')}
        >
          <PaymentMethodIcon iconId={iconId} />
        </PaymentMethodIconWrap>
      ))}
    </CardSchemeWrapper>
  );
};

CardSchemesCollection.propTypes = {
  iconIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

/**
 * @component
 */
export default CardSchemesCollection;
